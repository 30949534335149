export default function fetchApi(endPoint, payload = {}, method = "get") {
  //Setup Options
  const option = {
    method: method.toLowerCase(),
    mode: 'cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin':'*',
      // Authorization: "Bearer " + cookies.get("token")
    }
  };
  if (method.toLocaleLowerCase() !== "get") {
    option.body = JSON.stringify(payload);
  }
  //Fetch Data
  return fetch(endPoint, option)
    .then(results => {
      if (results.status === 401) {
        return 401;
      } else {
        return results.json();
      }
    })
    .then(data => {
      return data;
    })
}