//Modules
import React, { Component } from 'react';
import $ from "jquery";
import { Link } from "react-router-dom";

export default class Header extends Component {
    scrollToTop () {
        const body = $("html, body");
        body.stop().animate({scrollTop:0}, 500, 'swing');
    }
  render() {
    return (
      <header className='header'>
          <Link to="/" onClick={this.scrollToTop.bind(this)}>
            <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 134 194" xmlSpace="preserve"><g><g><path className="st0" d="M120.6,183.1c-3.5-2.1-5.3-5.6-5.9-10.4c-0.5-4.3-0.5-11.4-0.5-16.2v-94c0-13.6,0.8-46.3,0.8-56.4
            c0-1.1-0.8-1.6-1.3-1.9H71.1v1.1c2.1,0.3,6.1,0.5,8.8,2.1c3.2,2.1,4.8,4.8,5.6,9.6C86,21,86,31.6,86,36.2v110
            c0,24.8-10.9,37-25.8,37c-13,0-23.7-17-23.7-53c0-29.6,7.5-49,18.4-54.2c1.8-0.9,3.6-1.6,5.6-1.9l-0.1-0.3c0,0.2,0.1,0.3,0.1,0.3
            s0.2,0,0.5,0c0.2,0,0.4,0,0.5,0c0.5,0,0.8,0.1,1.3,0.3c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1l0.1,0.1c0.8,0.8,1.2,2,1,3.7
            c-0.5,3.8-3.9,6.7-4.7,10.5c-0.9,4,0.5,8.5,3.6,11.2c7.3,6.2,18.8,0.4,20.5-8.4c1.6-8.7-6.8-18.2-15.9-21c-2.3-0.7-5.7-1.1-9.8-1
            c-0.2,0-0.3,0-0.5,0C31,69.7,6.3,88.9,6.3,129.6c0,41.3,22.9,59.1,47.4,59.1c16.5,0,28.5-9.3,33-22.9H87v13c0,0.5-0.3,6.9-0.3,7.2
            c0,0.8,0.8,1.3,1.1,1.3c1.6,0,38.1-0.8,42.6-0.8v-1.1C128.1,185.3,123.3,185,120.6,183.1z"></path></g></g></svg>
            </Link>
            <div className="left">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/diana.dares/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@diana.dares" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-tiktok"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/dare.plussize" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.ca/DAREMagazine/_created/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-pinterest"></i>
                        </a>
                    </li>
                </ul>
                <Link to="/shop-my-looks">Shop my looks</Link>
            </div>
      </header>
    );
  }
}
