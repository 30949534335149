//Modules
import React, {Component} from 'react';

export default class Loading extends Component {
    componentDidMount() {
        for (let i = 0; i < 100; i++) {
            setTimeout(() => {
                document.getElementById("loading__progress").style.width = `${1 * i}%`;
                if (i + 1 === 100) {
                    document.getElementById("loading__wrapper").style.opacity = "0";
                    setTimeout(() => {
                        document.getElementById("loading__wrapper").style.display = "none";
                    }, 350);
                }
            }, 12 * i)
        }
  }
  render() {
    return (
        <div className="loading__wrapper" id="loading__wrapper">
            <div className="loading">
                <div className="loading__progress" id="loading__progress"/>
            </div>
        </div>
    );
  }
}
