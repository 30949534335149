//Modules
import React, { Component } from 'react';
import $ from "jquery";

export default class Loading extends Component {
    componentDidMount() {
        window.addEventListener("scroll", (e) => {
            if (window.pageYOffset > 300) {
                document.getElementById("back-to-top").classList.add("visible");
            } else {
                document.getElementById("back-to-top").classList.remove("visible");
            }
        });
    }

    scrollToTop () {
        const body = $("html, body");
        body.stop().animate({scrollTop:0}, 500, 'swing');
    }

    render() {
        return (
            <div id="back-to-top" onClick={this.scrollToTop.bind(this)}><i className="material-icons">keyboard_arrow_up</i></div>
        );
    }
}
