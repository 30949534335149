import React, { Component } from 'react';
import $ from "jquery";

import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import BackToTop from "../../Common/BackToTop";

export default class LikeToKnowIt extends Component {
    componentDidMount() {
        document.getElementById('ltk-widget-render').appendChild(document.getElementById('ltk-container'));
        const body = $("html, body");
        body.stop().animate({scrollTop:0}, 500, 'swing');
    }
    componentWillUnmount() {
        document.getElementById('ltk-container-holder').appendChild(document.getElementById('ltk-container'));
    }
  render() {
    return (
        <div className="app">
            <Header />
            <BackToTop />
            <section className="container">
                <div className="title" style={{ marginBottom: "0px"}}>
                    {/* <span className="title__bar title__bar--main" /> */}
                    <h1>Shop My Looks</h1>
                </div>
                <p className="subhead" style={{ textAlign: "center", marginTop: "-9px"}}>GET MY INSTAGRAM & TIKTOK OUTFITS</p>
                <p style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>Click on the photos below to shop my plus size outfits! If the items I’m wearing are no longer available, <br className="hide-break"/> I’ve included similar styles that I love, so that you can still get the look. Thanks for stopping by!</p>
                <div id="ltk-widget-render" />
                <div className="ltk-under">
                    <p className="subhead"><strong>DON'T SEE WHAT YOU'RE LOOKING FOR?</strong></p>
                    <p><a href="https://www.liketoknow.it/diana.dares" target="_blank">Click here</a> to see all of my looks.</p>
                </div>
            </section>
            <Footer />
        </div>
    );
  }
}
