import React, { Component } from 'react';

const image = require("../../../../images/darenewsletter.jpg");

export default class Newsletter extends Component {
  render() {
    return (
        <section id="newsletter">
            <div className="row">
                <div className="left">
                    <div className="image-container" style={{ backgroundImage: `url(${image})`}} />
                </div>
                <div className="right">
                    <h2>Let's be friends!</h2>
                    <p className="subhead">Sign up for the newsletter</p>
                    <p>Stay in the loop on all things curvy. Be the first to receive exclusive content, hear about upcoming collaborations, sales and much more.</p>
                    <form action="https://daremag.us3.list-manage.com/subscribe/post?u=f077b46f38b5b0f4b46c927d1&amp;id=763bbe1580" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <input placeholder="* First name" type="text" name="MMERGE9" className="" id="mce-MMERGE9" />
                        <input placeholder="* Last name" type="text" name="MMERGE5" className="" id="mce-MMERGE5" />
                        <input placeholder="* Email address" type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    </form>
                </div>
            </div>
        </section>
    );
  }
}
