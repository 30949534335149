import React from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import Home from "./Pages/Home/index";
import LikeToKnowIt from "./Pages/LikeToKnowIt/index";

function App() {
  return (
    <div className="app">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/shop-my-looks" component={LikeToKnowIt} />
        </Switch>
    </div>
  );
}

export default withRouter(App);