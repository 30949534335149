import React, { Component } from 'react';
import $ from "jquery";

import Header from "../../Common/Header";
import Loading from "../../Common/Loading";
import Feed from "./Sections/Feed";
import About from "./Sections/About";
import Magazine from "./Sections/Magazine";
import BackToTop from "../../Common/BackToTop";
import Newsletter from "./Sections/Newsletter";
import Footer from "../../Common/Footer";

export default class Home extends Component {
  componentDidMount() {
    const body = $("html, body");
    body.stop().animate({scrollTop:0}, 500, 'swing');
  }
  render() {
    return (
        <div className="app">
            <Header />
            <Loading />
            <BackToTop />
            <About />
            <Feed />
            <Magazine />
            <Newsletter />
            <Footer />
        </div>
    );
  }
}
