import React, { Component } from 'react';

const magazine = require("../../../../images/daremag.jpg");

export default class Feed extends Component {
  render() {
    return (
      <section id="magazine" className="container">
        <div className="row">
            <div className="left">
                <img src={magazine} alt="Magazine" />
            </div>
            <div className="right">
                <h2>The Magazine</h2>
                <p className="subhead">Curvy girl's go-to guide</p>
                <p>In 2013, I founded DARE Magazine as part of my university's thesis project. After spending years in the fashion industry, I couldn’t help but notice the lack of diversity in media. There was no one that looked like me in the pages of magazines! There had to be a change, and I wanted to make that change. DARE Magazine has grown to be the curvy girl’s go-to guide for fashion, beauty and lifestyle tips with icons like Chrissy Metz, Meghan Trainor, Shannon Purser and Nabela Noor (to name a few!) gracing our covers.</p>
                <a className="button button--disable">Stay tuned</a>
            </div>
        </div>
      </section>
    );
  }
}
