//Modules
import React, { Component } from 'react';
import fetchApi from "../../services/fetch.service";

// // Step 1 - get code
// https://api.instagram.com/oauth/authorize
//   ?client_id=1593842010819958
//   &redirect_uri=https://dare.style/
//   &scope=user_profile,user_media
//   &response_type=code


// // Step 2 - Exchange code for access
// curl -X POST \
//   https://api.instagram.com/oauth/access_token \
//   -F client_id=1593842010819958 \
//   -F client_secret=3f58d8fe40c76fa7a96faf5bac22370f \
//   -F grant_type=authorization_code \
//   -F redirect_uri=https://dare.style/ \
//   -F code={step 1}

// // Step 3 - Long lived token
// https://graph.instagram.com/access_token
//     ?grant_type=ig_exchange_token
//     &client_secret=3f58d8fe40c76fa7a96faf5bac22370f
//     &access_token={step 2}

// Refresh Token
// https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=IGQVJWLWpMbnpNYUN2Q2RyUkJnQmU1SV9OMW1PR0VVTHhSR0RQYnZAEQ0UtbDREcGZAQYlF2U1FCdGM5U0luV0VYSDZAMY3hxcGs5MGpkZA2V6dy1tSk1SQk9Pcnp2TEFuc1ZAET1pJNEdn

export default class Footer extends Component {
    constructor(props) {
        super();
        this.state = {
            images: [],
            loading: true,
        }
        this.instagram();
    }
    instagram() {
        const token = "IGQVJYZADZAhWXdxMEV5R0VqLUJ0a21xLTYtY2RTY2FjM2k1OHlIMU1TZAkswZAWtRWEhZAd1hxOXhMV3lwTnk1bHp5MTRfLVY1Q0RjOEI5NXVseDAtYkxscmJ2aGxQdWRRSG55SHJBZAm1n";
        const url = `https://graph.instagram.com/me/media?fields=id,permalink,thumbnail_url,media_type,media_url,username,timestamp&access_token=${token}`;

        const config = {
            method: "get",
            endPoint: url,
            payload: {}
          };
          fetchApi(config.endPoint, config.payload, config.method)
              .then(data => {
                this.setState({ images: data ? data.data : [], loading: false });
            })
    }
    image(image) {
        if (image.media_type === "VIDEO") {
            return image.thumbnail_url;
        }
        return image.media_url;

    }
  render() {
    return (
        <React.Fragment>
            {!this.state.loading &&
                <section id="instagram">
                    <div className="title">
                        <span className="title__bar"/>
                        <h2>Follow me </h2>
                </div>
                {this.state.images.length > 0 &&
                    <div className="instagram-feed">
                        <a target="_blank" rel="noopener noreferrer" href={this.state.images[0].permalink} className="instagram-feed__image instagram-feed__image--large" style={{ backgroundImage: `url(${this.image(this.state.images[0])})` }} />
                        <div className="instagram-feed__column">
                            <a target="_blank" rel="noopener noreferrer" href={this.state.images[1].permalink} className="instagram-feed__image instagram-feed__image--small" style={{ backgroundImage: `url(${this.image(this.state.images[1])}` }} />
                            <a target="_blank" rel="noopener noreferrer" href={this.state.images[2].permalink} className="instagram-feed__image instagram-feed__image--small" style={{ backgroundImage: `url(${this.image(this.state.images[2])})` }} />
                        </div>
                        <a target="_blank" rel="noopener noreferrer" href={this.state.images[3].permalink} className="instagram-feed__image instagram-feed__image--large" style={{ backgroundImage: `url(${this.image(this.state.images[3])})` }} />
                        <div className="instagram-feed__column">
                            <a target="_blank" rel="noopener noreferrer" href={this.state.images[4].permalink} className="instagram-feed__image instagram-feed__image--small" style={{ backgroundImage: `url(${this.image(this.state.images[4])})` }} />
                            <a target="_blank" rel="noopener noreferrer" href={this.state.images[5].permalink} className="instagram-feed__image instagram-feed__image--small" style={{ backgroundImage: `url(${this.image(this.state.images[5])})` }} />
                        </div>
                    </div>
                }
                </section>
            }
            <footer>
                <ul>
                    <li>
                        <a href="https://www.instagram.com/diana.dares/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@diana.dares" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-tiktok"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/dare.plussize" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.ca/DAREMagazine/_created/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-pinterest"></i>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:diana@dare.style" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-envelope"></i>
                        </a>
                    </li>
                </ul>
                <p>Copyright {new Date().getFullYear()} © Diana Di Poce</p>
            </footer>
        </React.Fragment>
    );
  }
}
