import React, { Component } from 'react';

const image1 = require("../../../../images/ddp1.jpg");
const image2 = require("../../../../images/ddp2.jpg");
const image3 = require("../../../../images/ddp3.jpg");

export default class Feed extends Component {
  render() {
    return (
      <section id="feed" className="container container--large">
        <div className="row">
          <div className="image-container">
            <img src={image1} alt="Diana" />
          </div>
          <div className="image-container">
            <img src={image2} alt="Diana" />
          </div>
          <div className="image-container">
            <img src={image3} alt="Diana" />
          </div>
        </div>
      </section>
    );
  }
}
