import React, { Component } from 'react';
import HeyThere from "./components/HeyThere";
import XOXODiana from "./components/XOXODiana";

const about = require("../../../../images/about.jpg");

export default class About extends Component {
  render() {
    return (
        <section className="container" id="about">
            <div className="row">
                <div className="left">
                    <img src={about} alt="Diana" />
                </div>
                <div className="right">
                    <HeyThere />
                    <p className="subhead">It’s lovely to meet you!</p>
                    <p>I’m Diana - the creator behind Diana Dares and DARE Magazine, your destinations for all things curvy. I’ve made it my mission to show the world that style has no size through breaking so-called fashion rules and sharing styling tips, while loving the skin I’m in. I hope to empower and inspire you to be daring with your style, no matter the size on your label. Thanks for stopping by!</p>
                    <XOXODiana />
                </div>
            </div>
        </section>
    );
  }
}
