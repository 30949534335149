import React, { Component } from 'react';

export default class XOXODiana extends Component {
    render() {
    return (
<svg version="1.1" id="xoxo-diana" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 318.76 74.68" style={{enableBackground: "new 0 0 318.76 74.68"}} xmlSpace="preserve">
<g>
	<defs>
		<rect id="SVGID_1_" width="318.76" height="74.68"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlinkHref="#SVGID_1_"  style={{overflow: "visible"}} />
	</clipPath>
	<path className="st0" d="M293.06,47.84c-0.11,0.28-0.16,0.53-0.16,0.74c-0.07,0-0.32,0.07-0.74,0.21c-0.14,0.07-0.81,0.71-2.02,1.91
		c-1.2,1.2-2.62,2.69-4.24,4.46c-1.42,1.49-2.94,3.08-4.56,4.77c-1.63,1.7-3.25,3.33-4.88,4.88s-3.15,2.94-4.56,4.14
		c-1.42,1.2-2.62,2.05-3.61,2.54c-0.71,0.28-1.27,0.25-1.7-0.1c-0.42-0.35-0.53-0.88-0.32-1.59c0.35-1.34,1.2-3.02,2.54-5.04
		c1.35-2.02,2.92-4.03,4.72-6.05c1.8-2.01,3.66-3.92,5.57-5.73c1.91-1.8,3.61-3.16,5.09-4.09c1.84-1.2,3.52-2.07,5.04-2.6
		c1.52-0.53,2.72-0.81,3.61-0.85c0.88-0.03,1.43,0.13,1.65,0.48c0.21,0.36-0.04,0.78-0.74,1.27
		C293.4,47.35,293.17,47.56,293.06,47.84 M294.81,48.79c1.49-1.13,2.23-2.19,2.23-3.18c0-0.64-0.28-1.17-0.85-1.59
		c-0.57-0.42-1.42-0.64-2.55-0.64s-2.53,0.25-4.19,0.74c-1.66,0.5-3.5,1.36-5.51,2.6c-2.02,1.24-4.19,2.85-6.52,4.83
		c-2.33,1.98-4.74,4.45-7.21,7.42c-0.49,0.64-1.06,1.38-1.7,2.23c-0.64,0.85-1.25,1.75-1.86,2.7c-0.6,0.95-1.15,1.95-1.64,2.97
		c-0.49,1.03-0.88,1.96-1.17,2.81c-0.43,1.27-0.5,2.26-0.21,2.97c0.28,0.71,0.73,1.17,1.33,1.38s1.31,0.23,2.12,0.05
		c0.81-0.18,1.54-0.44,2.18-0.8c1.48-0.85,3.15-2.05,4.98-3.61c1.84-1.56,3.71-3.27,5.62-5.14c1.91-1.87,3.75-3.75,5.52-5.62
		c1.77-1.87,3.36-3.55,4.77-5.04l0.21-0.21c-0.43,1.13-0.87,2.37-1.33,3.71c-0.46,1.34-0.8,2.4-1.01,3.18
		c-0.35,2.26-0.16,3.85,0.58,4.77c0.74,0.92,1.86,1.38,3.34,1.38s3.34-0.44,5.57-1.33c2.23-0.88,4.54-1.96,6.95-3.24
		c2.4-1.27,4.77-2.61,7.11-4.03c2.33-1.42,4.38-2.65,6.15-3.71c0.28-0.14,0.55-0.34,0.8-0.58c0.25-0.25,0.3-0.55,0.16-0.9
		c-0.14-0.28-0.39-0.44-0.74-0.48c-0.35-0.03-0.71,0.05-1.06,0.27c-1.84,1.13-3.61,2.21-5.3,3.24c-1.7,1.02-3.47,2.03-5.3,3.02v-0.1
		c-6.72,3.68-11.18,5.51-13.37,5.51c-1.49,0-2.23-0.81-2.23-2.44c0-1.91,0.74-4.74,2.23-8.48l0.21-0.64v0.11
		c0.21-0.57,0.48-1.22,0.8-1.96s0.41-1.36,0.27-1.86L294.81,48.79z M234.99,51.98c-0.57,0-1.1,0.32-1.59,0.95
		c-0.21,0.35-0.69,1.11-1.43,2.28c-0.74,1.17-1.59,2.51-2.55,4.03c-0.95,1.52-1.94,3.15-2.97,4.88c-1.02,1.73-1.94,3.33-2.76,4.77
		c-0.81,1.45-1.43,2.69-1.86,3.71c-0.42,1.03-0.53,1.61-0.32,1.75c0.28,0.21,0.64,0.25,1.06,0.1c0.43-0.14,0.83-0.41,1.22-0.79
		c0.39-0.39,0.78-0.8,1.17-1.22c0.39-0.42,0.72-0.81,1.01-1.17l0.21-0.21l2.12-2.65v0.1c0.99-1.13,2.19-2.56,3.61-4.29
		c1.42-1.73,2.95-3.52,4.61-5.36s3.32-3.64,4.99-5.41c1.66-1.77,3.2-3.29,4.61-4.56c1.42-1.27,2.63-2.16,3.66-2.65
		c1.02-0.49,1.68-0.42,1.96,0.21c0.35,0.71,0.41,1.75,0.16,3.13c-0.25,1.38-0.55,2.88-0.9,4.51c-0.36,1.35-0.66,2.69-0.9,4.03
		c-0.25,1.34-0.37,2.62-0.37,3.82c0,1.42,0.28,2.56,0.85,3.45c0.57,0.88,1.59,1.33,3.08,1.33c0.99,0,2.3-0.28,3.92-0.85
		c1.62-0.57,3.61-1.52,5.94-2.86c1.84-0.99,3.62-2.05,5.36-3.18c1.73-1.13,3.48-2.3,5.25-3.5l2.87-1.91
		c0.35-0.14,0.6-0.37,0.74-0.69c0.14-0.32,0.21-0.58,0.21-0.8c-0.28-0.71-0.88-0.78-1.8-0.21l-1.91,1.27
		c-1.13,0.78-2.37,1.61-3.71,2.49c-1.34,0.89-2.72,1.75-4.14,2.6c-1.42,0.85-2.83,1.66-4.24,2.44c-1.42,0.78-2.72,1.42-3.92,1.91
		c-0.57,0.21-1.17,0.41-1.8,0.58c-0.64,0.18-1.13,0.27-1.48,0.27c-0.92,0-1.56-0.32-1.91-0.95c-0.35-0.64-0.53-1.45-0.53-2.44
		c0-0.99,0.12-2.14,0.37-3.45c0.25-1.31,0.51-2.56,0.8-3.77c0.42-2.05,0.74-3.94,0.95-5.68c0.21-1.73,0-2.95-0.64-3.66
		c-0.85-0.99-2.09-1.11-3.71-0.37c-1.63,0.74-3.34,1.86-5.15,3.34c-1.8,1.49-3.55,3.11-5.25,4.88c-1.7,1.77-3.04,3.22-4.03,4.35
		l-0.64,0.74c-0.64,0.71-1.35,1.56-2.12,2.55c-0.78,0.99-1.63,2.02-2.54,3.08l-3.18,3.82c1.41-2.55,2.9-5,4.46-7.37
		c1.55-2.37,2.83-4.33,3.82-5.89C236.19,52.47,235.98,51.98,234.99,51.98 M209.8,47.84c-0.11,0.28-0.16,0.53-0.16,0.74
		c-0.07,0-0.32,0.07-0.74,0.21c-0.14,0.07-0.81,0.71-2.02,1.91c-1.2,1.2-2.62,2.69-4.24,4.46c-1.42,1.49-2.94,3.08-4.56,4.77
		c-1.63,1.7-3.25,3.33-4.88,4.88c-1.63,1.56-3.15,2.94-4.56,4.14c-1.41,1.2-2.61,2.05-3.61,2.54c-0.71,0.28-1.27,0.25-1.7-0.1
		c-0.43-0.35-0.53-0.88-0.32-1.59c0.35-1.34,1.2-3.02,2.55-5.04c1.34-2.02,2.92-4.03,4.72-6.05c1.8-2.01,3.66-3.92,5.57-5.73
		c1.91-1.8,3.61-3.16,5.09-4.09c1.84-1.2,3.52-2.07,5.04-2.6c1.52-0.53,2.72-0.81,3.61-0.85c0.88-0.03,1.43,0.13,1.64,0.48
		c0.21,0.36-0.04,0.78-0.74,1.27C210.13,47.35,209.9,47.56,209.8,47.84 M211.55,48.79c1.49-1.13,2.23-2.19,2.23-3.18
		c0-0.64-0.28-1.17-0.85-1.59c-0.57-0.42-1.42-0.64-2.54-0.64c-1.13,0-2.53,0.25-4.19,0.74c-1.66,0.5-3.5,1.36-5.52,2.6
		c-2.02,1.24-4.19,2.85-6.52,4.83c-2.33,1.98-4.74,4.45-7.21,7.42c-0.49,0.64-1.06,1.38-1.7,2.23c-0.64,0.85-1.25,1.75-1.86,2.7
		c-0.6,0.95-1.15,1.95-1.64,2.97c-0.49,1.03-0.88,1.96-1.17,2.81c-0.42,1.27-0.49,2.26-0.21,2.97c0.28,0.71,0.73,1.17,1.32,1.38
		c0.6,0.21,1.31,0.23,2.12,0.05c0.81-0.18,1.54-0.44,2.17-0.8c1.49-0.85,3.15-2.05,4.99-3.61c1.84-1.56,3.71-3.27,5.62-5.14
		c1.91-1.87,3.75-3.75,5.51-5.62c1.77-1.87,3.36-3.55,4.77-5.04l0.21-0.21c-0.42,1.13-0.87,2.37-1.33,3.71
		c-0.46,1.34-0.79,2.4-1.01,3.18c-0.35,2.26-0.16,3.85,0.58,4.77c0.74,0.92,1.86,1.38,3.34,1.38c1.49,0,3.34-0.44,5.57-1.33
		c2.23-0.88,4.54-1.96,6.95-3.24c2.4-1.27,4.77-2.61,7.11-4.03c2.33-1.42,4.38-2.65,6.15-3.71c0.28-0.14,0.55-0.34,0.79-0.58
		c0.25-0.25,0.3-0.55,0.16-0.9c-0.14-0.28-0.39-0.44-0.74-0.48c-0.36-0.03-0.71,0.05-1.06,0.27c-1.84,1.13-3.61,2.21-5.3,3.24
		c-1.7,1.02-3.47,2.03-5.3,3.02v-0.1c-6.72,3.68-11.17,5.51-13.36,5.51c-1.49,0-2.23-0.81-2.23-2.44c0-1.91,0.74-4.74,2.23-8.48
		l0.21-0.64v0.11c0.21-0.57,0.48-1.22,0.79-1.96c0.32-0.74,0.41-1.36,0.27-1.86L211.55,48.79z M170.61,33.89
		c-0.43,0.74-0.97,1.61-1.65,2.6c-0.67,0.99-1.34,1.93-2.01,2.81c-0.67,0.89-1.08,1.43-1.22,1.65v-0.11
		c-0.42,0.42-0.57,0.8-0.42,1.11c0.14,0.32,0.42,0.48,0.85,0.48c0.21,0,0.49-0.09,0.85-0.27c0.35-0.18,0.64-0.41,0.85-0.69
		c0.49-0.64,1.24-1.63,2.23-2.97c0.99-1.34,1.84-2.65,2.54-3.92c0.71-1.27,1.04-2.35,1.01-3.24c-0.03-0.88-0.83-1.18-2.39-0.9
		c-0.35,0.14-0.69,0.34-1.01,0.58c-0.32,0.25-0.51,0.51-0.58,0.8v-0.11c-0.5,0.99-0.14,1.38,1.06,1.17
		C171.06,32.81,171.03,33.15,170.61,33.89 M163.71,52.13c-0.21-0.25-0.53-0.3-0.95-0.16c-0.36,0.07-0.67,0.21-0.96,0.42
		c-0.28,0.21-0.49,0.42-0.64,0.64c-0.64,1.06-1.34,2.33-2.12,3.82c-0.78,1.49-1.52,3.02-2.23,4.61c-0.71,1.59-1.31,3.15-1.8,4.67
		c-0.49,1.52-0.74,2.88-0.74,4.09c0,1.27,0.32,2.32,0.95,3.13c0.64,0.81,1.77,1.22,3.39,1.22c1.06,0,2.12-0.14,3.18-0.42
		c2.12-0.64,4.62-1.75,7.48-3.34c2.86-1.59,5.78-3.36,8.75-5.3c2.97-1.94,5.87-3.92,8.7-5.94c2.83-2.02,5.23-3.77,7.21-5.25
		c0.28-0.21,0.49-0.49,0.64-0.85c0.14-0.35,0.14-0.6,0-0.74c-0.21-0.28-0.51-0.41-0.9-0.37c-0.39,0.04-0.69,0.2-0.9,0.48
		c-2.62,1.91-5.23,3.8-7.85,5.68c-2.62,1.88-5.27,3.73-7.95,5.57c-4.39,2.83-7.97,4.88-10.77,6.15c-2.8,1.27-4.93,1.91-6.42,1.91
		c-1.84,0-2.76-0.99-2.76-2.97c0-1.77,0.6-4.01,1.8-6.74c1.2-2.72,2.76-5.74,4.67-9.07C163.85,52.79,163.92,52.38,163.71,52.13
		 M173.58,3.23c1.62-0.53,3.11-0.8,4.46-0.8c1.62,0,2.93,0.41,3.92,1.22c0.99,0.81,1.31,2.03,0.95,3.66
		c-0.43,2.05-1.59,4.33-3.5,6.84c-1.91,2.51-4.3,5.11-7.16,7.8c-2.86,2.69-6.06,5.41-9.6,8.17c-3.54,2.76-7.13,5.43-10.77,8.01
		c-3.64,2.58-7.21,5-10.71,7.27c-3.5,2.26-6.63,4.28-9.39,6.05c1.98-3.04,4.46-6.7,7.43-10.98c2.97-4.28,6.13-8.61,9.49-12.99
		c3.36-4.39,6.79-8.52,10.29-12.41c3.5-3.89,6.77-6.96,9.81-9.23C170.36,4.63,171.95,3.77,173.58,3.23 M128.92,52.08l-0.95,1.49
		l-6.15,4.35c-4.74,3.18-8.13,5.13-10.18,5.83c-2.05,0.71-3.08,0.64-3.08-0.21c0-0.49,0.21-1.13,0.64-1.91s1.01-1.65,1.75-2.6
		c0.74-0.95,1.57-1.94,2.49-2.97c0.92-1.02,1.87-2.03,2.86-3.02c1.13-0.99,2.3-1.93,3.5-2.81c1.2-0.88,2.4-1.66,3.61-2.33
		c1.2-0.67,2.37-1.18,3.5-1.54c1.13-0.35,2.12-0.46,2.97-0.32c0.35,0.14,0.48,0.48,0.37,1.01c-0.1,0.53-0.41,0.97-0.9,1.32
		c-0.85,0.71-0.99,1.27-0.43,1.7c0.14,0.35,0.57,0.35,1.27,0L128.92,52.08z M184.34,1.75C183.03,0.58,181.28,0,179.09,0
		c-1.63,0-3.47,0.37-5.51,1.11c-2.05,0.74-4.03,1.82-5.94,3.24c-2.76,2.12-5.68,4.84-8.75,8.17c-3.08,3.33-6.15,6.89-9.23,10.71
		c-3.08,3.82-6.03,7.67-8.86,11.56s-5.34,7.46-7.53,10.71c-0.14-0.99-0.78-1.62-1.91-1.91c-1.13-0.21-2.37-0.14-3.71,0.21
		c-1.35,0.35-2.74,0.9-4.19,1.64c-1.45,0.74-2.92,1.66-4.4,2.76c-1.49,1.1-2.9,2.25-4.24,3.45c-1.84,1.7-3.48,3.45-4.93,5.25
		c-1.45,1.8-2.51,3.45-3.18,4.93c-0.67,1.49-0.88,2.69-0.64,3.61c0.25,0.92,1.15,1.31,2.7,1.17c0.99-0.07,2.16-0.37,3.5-0.9
		c1.34-0.53,2.77-1.22,4.3-2.07c1.52-0.85,3.04-1.8,4.56-2.86c1.52-1.06,2.99-2.09,4.4-3.08c-0.99,2.05-1.91,4.21-2.76,6.47
		c-0.85,2.26-1.27,4.21-1.27,5.83c0,1.35,0.35,2.44,1.06,3.29c0.71,0.85,1.87,1.27,3.5,1.27c0.85,0,1.84-0.12,2.97-0.37
		c1.13-0.25,2.4-0.65,3.82-1.22h-0.11c2.19-0.92,4.58-2.1,7.16-3.55c2.58-1.45,5.2-3.02,7.85-4.72c2.65-1.7,5.23-3.45,7.74-5.25
		c2.51-1.8,4.83-3.52,6.95-5.14c0.35-0.21,0.58-0.49,0.69-0.85c0.11-0.35,0.09-0.6-0.05-0.74c-0.21-0.28-0.51-0.41-0.9-0.37
		c-0.39,0.04-0.69,0.2-0.9,0.48l-2.02,1.49c-3.47,2.55-6.82,4.9-10.08,7.06c-3.25,2.16-6.26,4.03-9.02,5.62
		c-2.76,1.59-5.25,2.83-7.48,3.71c-2.23,0.88-4.08,1.32-5.57,1.32c-1.98,0-2.97-0.99-2.97-2.97c0-1.41,0.42-3.25,1.27-5.51
		c0.85-2.26,2.23-5.09,4.14-8.49c2.76-1.7,5.96-3.73,9.6-6.1c3.64-2.37,7.44-4.93,11.4-7.69c3.96-2.76,7.9-5.66,11.83-8.7
		c3.92-3.04,7.51-6.06,10.77-9.07c3.25-3.01,5.99-5.94,8.22-8.8c2.23-2.87,3.66-5.5,4.29-7.9C186.09,4.6,185.65,2.92,184.34,1.75
		 M61.62,51.76c0.32,0.07,0.69-0.03,1.11-0.32l-0.11,0.11c0.85-0.35,1.27-0.88,1.27-1.59c-0.21-1.2-1.24-1.7-3.08-1.49
		c-2.33,0.5-4.53,1.61-6.58,3.34c-2.05,1.73-3.87,3.7-5.46,5.89c-1.59,2.19-2.84,4.44-3.76,6.74c-0.92,2.3-1.38,4.26-1.38,5.89
		c0,1.7,0.53,2.9,1.59,3.61c0.85,0.57,1.84,0.8,2.97,0.69c1.13-0.11,2.3-0.44,3.5-1.01c1.2-0.57,2.39-1.33,3.55-2.28
		s2.24-2,3.24-3.13c0.64-0.78,1.29-1.66,1.96-2.65c0.67-0.99,1.36-2.09,2.07-3.29c0.64,0.21,1.36,0.35,2.17,0.42
		c0.81,0.07,1.75,0.14,2.81,0.21c2.76,0,5.87-0.58,9.33-1.75c3.46-1.17,6.89-3.09,10.29-5.78l1.38-1.27
		c0.71-0.71,0.67-1.31-0.11-1.8c-0.14-0.07-0.39-0.05-0.74,0.05c-0.35,0.11-0.64,0.3-0.85,0.58l-1.59,1.38
		c-2.48,1.98-5.25,3.5-8.33,4.56c-3.08,1.06-5.89,1.59-8.43,1.59c-0.78,0-1.59-0.09-2.44-0.27c-0.85-0.17-1.56-0.37-2.12-0.58
		c0.78-1.63,1.27-3.13,1.49-4.51c0.21-1.38-0.18-2.28-1.17-2.7c-0.43-0.21-0.94-0.17-1.54,0.11c-0.6,0.28-1.17,0.73-1.7,1.32
		c-0.53,0.6-0.99,1.27-1.38,2.02c-0.39,0.74-0.58,1.5-0.58,2.28c0,0.57,0.1,1.11,0.32,1.64c0.21,0.53,0.6,1.01,1.17,1.43
		c-1.13,2.19-2.53,4.3-4.19,6.31c-1.66,2.01-3.41,3.38-5.25,4.08c-1.63,0.57-2.81,0.64-3.55,0.21s-1.11-1.2-1.11-2.33
		c0-1.06,0.25-2.32,0.74-3.77c0.5-1.45,1.17-2.93,2.02-4.46c0.85-1.52,1.84-2.99,2.97-4.4c1.13-1.41,2.37-2.65,3.71-3.71
		c0.35-0.28,0.81-0.62,1.38-1.01c0.57-0.39,1.13-0.71,1.7-0.95c0.57-0.25,1.04-0.41,1.43-0.48c0.39-0.07,0.62,0.07,0.69,0.42
		C61.1,51.48,61.3,51.69,61.62,51.76 M36.64,43.33c-0.35-0.11-0.81-0.05-1.38,0.16c-0.28,0.14-0.53,0.5-0.74,1.06v-0.11
		c-0.21,0.43-0.76,1.04-1.65,1.86c-0.88,0.81-1.98,1.75-3.29,2.81c-1.31,1.06-2.8,2.21-4.46,3.45c-1.66,1.24-3.38,2.49-5.14,3.77
		c-0.99,0.71-1.96,1.43-2.92,2.17s-1.89,1.47-2.81,2.17c0.14-2.33,0.64-4.91,1.49-7.74c0.21-0.78-0.18-1.17-1.17-1.17
		c-0.78,0.28-1.24,0.71-1.38,1.27c-0.57,1.7-0.99,3.34-1.27,4.93c-0.28,1.59-0.39,3.13-0.32,4.62c-1.7,1.34-3.27,2.6-4.72,3.76
		c-1.45,1.17-2.7,2.23-3.77,3.18c-1.06,0.95-1.87,1.77-2.44,2.44c-0.56,0.67-0.78,1.15-0.64,1.43c0.35,0.57,0.64,0.9,0.85,1.01
		c0.21,0.11,0.39,0.2,0.53,0.27c0.5,0,0.9-0.1,1.22-0.32c0.32-0.21,0.55-0.46,0.69-0.74H3.22c0.35-0.49,0.39-0.88,0.11-1.17
		c0.57-0.78,1.68-1.86,3.34-3.23c1.66-1.38,3.38-2.77,5.14-4.19c0.35,1.77,1.18,3.2,2.49,4.29c1.31,1.1,3.2,1.65,5.68,1.65
		c1.13,0,2.23-0.11,3.29-0.32c2.4-0.42,4.99-1.18,7.74-2.28c2.76-1.1,5.51-2.42,8.27-3.98c2.76-1.56,5.46-3.24,8.11-5.04
		c2.65-1.8,5.14-3.55,7.48-5.25c0.42-0.42,0.39-0.99-0.11-1.7c-0.5-0.28-1.03-0.14-1.59,0.42h0.1c-3.96,2.97-8.27,5.84-12.94,8.59
		c-4.67,2.76-9.19,4.81-13.58,6.15c-1.13,0.36-2.21,0.6-3.24,0.74c-1.02,0.14-1.93,0.21-2.7,0.21c-2.12,0-3.68-0.49-4.67-1.49
		c-0.99-0.99-1.59-2.3-1.8-3.92l4.67-3.61l1.38-1.06c1.2-0.85,2.74-1.96,4.61-3.34c1.87-1.38,3.71-2.79,5.52-4.24
		c1.8-1.45,3.34-2.79,4.61-4.03c1.27-1.24,1.94-2.17,2.02-2.81C37.17,43.67,36.99,43.44,36.64,43.33"/>
</g>
</svg>
    );
  }
}
